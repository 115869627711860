import React, { useEffect, useMemo, useState } from 'react';
import { MetadataInputProps } from 'components/input/Metadata/types/metadataInputProps.types';
import { MetadataSearch } from 'components/input/Metadata/shared';
import { XLocationMetaDataList, XMetadataItem } from '../types';
import { PropertyMapping } from '../helpers/properties-mapping';

interface Props extends MetadataInputProps {
    response?: XLocationMetaDataList;
    config: Config;
    value: XMetadataItem[];
}

interface Config {
    count?: number;
    location_type?: string;
    q?: string;
}

const XGenericSelectMultiple = ({ response, config, value, setRequest, onMutation, metadataType, selectType }: Props) => {
    const [q, setQ] = useState<string | undefined>('');
    const [customConfig, setCustomConfig] = useState<Config>(config);

    useEffect(() => {
        // set parameters to start a request
        setRequest({
            params: {
                ...config,
                q
            }
        });
    }, [JSON.stringify(config), q]);

    // Formatting the options based on the resposne
    const getOptions = (response: XLocationMetaDataList | undefined): XMetadataItem[] => {
        if (!response || !response.data || !response.data.length) return [];

        // Mapped properties of responses of the metadata
        const MetadataProperties = PropertyMapping[metadataType];
        return response.data.reduce((acc: XMetadataItem[], item) => {
            // Check if the item is not selected already
            let isItemSelected = false;

            if (Array.isArray(value)) {
                isItemSelected = value
                    ? value.some((selectedItem: XMetadataItem) => selectedItem[MetadataProperties.id] === item[MetadataProperties.id])
                    : false;
            } else if (value && Object.keys(value).length > 0) {
                isItemSelected = value[MetadataProperties.id] === item[MetadataProperties.id];
            }

            // Retrieve the options which include q
            if (!isItemSelected && q && item[MetadataProperties.name].toLowerCase().includes(q.toLowerCase()))
                acc.push({ ...item, id: item[MetadataProperties.id], type: item[MetadataProperties.type], name: item[MetadataProperties.name] });
            return acc;
        }, []);
    };

    const options = useMemo(() => getOptions(response), [response]);

    return (
        <MetadataSearch
            isQueryParamRequired
            options={options}
            value={value}
            onMutation={onMutation}
            setCustomConfig={setCustomConfig}
            customConfig={customConfig}
            q={q}
            setQ={setQ}
            selectType={selectType}
        />
    );
};

export default XGenericSelectMultiple;
