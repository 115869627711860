export const PropertyMapping = {
    linkedInAdAccount: {
        name: 'account',
        id: 'account'
    },
    linkedInCampaignGroup: {
        name: 'name',
        id: 'id'
    },
    linkedInCampaign: {
        name: 'name',
        id: 'id'
    }
};
